<!--
 * @Description: 核心词
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 22:05:58
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/examine/mods/coreWords.vue
-->
<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">素材审核</div>
    <div class="content-head">
      <div class="head-btn">
        <!-- <div class="btn">上传核心词</div> -->
        <div class="btn" @click="handleAudited">审核通过</div>
      </div>
    </div>
    <div class="tip">
      <div class="tip-left">说明：</div>
      <div class="tip-right">
        <div>
          1、核心词很容易被定义，就是和自己业务相关度高的关键词就是核心关键词。
        </div>
        <div>2、请在上传完所有核心词之后，点击提交审核。</div>
      </div>
    </div>
    <div class="source-material-list" v-loading="isLoading">
      <div
        class="source-material-item"
        v-for="item in tableData"
        :key="item.id"
      >
        <div class="item-content">
          <div class="li"></div>
          <div class="li-text">{{ item.material_detail.content }}</div>
        </div>
        <div class="item-opts">
          <div class="item-btn item-edit" style="margin-right: 20px">
            <span
              @click="
                () => {
                  edit = {
                    show: true,
                    id: id,
                    data: {
                      ...item,
                    },
                  };
                }
              "
            >
              <i class="el-icon-edit"></i> 编辑</span
            >
          </div>
          <div class="item-btn item-remove">
            <span @click="handleRemove(item)">
              <i class="el-icon-delete"></i> 删除</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 编辑 -->
    <CoreEdit
      v-if="edit.show"
      :show.sync="edit.show"
      v-bind="edit"
      @update="getList()"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";
import CoreEdit from "../components/core.vue";

const URL = {
  taskDetail: "api/operation/material/taskDetail",
  remove: "api/operation/material/remove",
  audited: "api/operation/material/audited",
};

export default {
  components: {
    CoreEdit,
  },
  data() {
    return {
      id: this.$route.query?.id,
      tableData: [],
      edit: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.taskDetail,
        method: "get",
        data: {
          id: this.id,
        },
      });
      this.tableData = res.log || [];
      this.isLoading = false;
    },
    handleRemove({ id }) {
      this.$confirm("确认删除此数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.remove,
            method: "post",
            data: {
              auditId: this.id,
              id,
            },
          }).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        })
        .catch(() => {
          this.$message.info("已取消【删除】操作");
        });
    },
    handleAudited() {
      this.$confirm("确认通过？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.audited,
            method: "post",
            data: {
              id: this.id,
            },
          }).then(() => {
            this.$message.success("审核成功");
            this.getList();
          });
        })
        .catch(() => {
          this.$message.info("已取消【审核】操作");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  display: flex;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
  font-weight: normal;
  color: #7c7f97;
  line-height: 24px;
  margin-top: 10px;
}
.source-material-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-left: 1px solid #e4e8fc;
  margin-top: 14px;
  .source-material-item {
    width: 25%;
    padding: 40px 20px;
    border-right: 1px solid #e4e8fc;
    border-bottom: 1px solid #e4e8fc;
    border-top: 1px solid #e4e8fc;
    .item-content {
      display: flex;
      align-items: center;
      justify-content: center;
      .li {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #7c7f97;
        margin-right: 10px;
      }
      .li-text {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #7c7f97;
      }
    }
    .item-opts {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item-btn {
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #7c7f97;
      margin-top: 20px;
    }
    .item-edit {
      &:hover {
        color: #ff7800;
        cursor: pointer;
      }
    }
    .item-remove {
      &:hover {
        color: #4e6bef;
        cursor: pointer;
      }
    }
    &:hover {
      background-color: #f6f6fd;
    }
  }
}
.content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .head-btn {
    display: flex;
    align-items: center;
    .btn {
      width: 128px;
      height: 33px;
      background: #4e6bef;
      margin-right: 12px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>
