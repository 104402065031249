var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-wrapper wrapper-view"},[_c('div',{staticClass:"wrapper-title"},[_vm._v("素材审核")]),_c('div',{staticClass:"content-head"},[_c('div',{staticClass:"head-btn"},[_c('div',{staticClass:"btn",on:{"click":_vm.handleAudited}},[_vm._v("审核通过")])])]),_vm._m(0),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"source-material-list"},_vm._l((_vm.tableData),function(item){return _c('div',{key:item.id,staticClass:"source-material-item"},[_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"li"}),_c('div',{staticClass:"li-text"},[_vm._v(_vm._s(item.material_detail.content))])]),_c('div',{staticClass:"item-opts"},[_c('div',{staticClass:"item-btn item-edit",staticStyle:{"margin-right":"20px"}},[_c('span',{on:{"click":() => {
                _vm.edit = {
                  show: true,
                  id: _vm.id,
                  data: {
                    ...item,
                  },
                };
              }}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v(" 编辑")])]),_c('div',{staticClass:"item-btn item-remove"},[_c('span',{on:{"click":function($event){return _vm.handleRemove(item)}}},[_c('i',{staticClass:"el-icon-delete"}),_vm._v(" 删除")])])])])}),0),(_vm.edit.show)?_c('CoreEdit',_vm._b({attrs:{"show":_vm.edit.show},on:{"update:show":function($event){return _vm.$set(_vm.edit, "show", $event)},"update":function($event){return _vm.getList()}}},'CoreEdit',_vm.edit,false)):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip"},[_c('div',{staticClass:"tip-left"},[_vm._v("说明：")]),_c('div',{staticClass:"tip-right"},[_c('div',[_vm._v(" 1、核心词很容易被定义，就是和自己业务相关度高的关键词就是核心关键词。 ")]),_c('div',[_vm._v("2、请在上传完所有核心词之后，点击提交审核。")])])])
}]

export { render, staticRenderFns }