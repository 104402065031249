<!--
 * @Description: 编辑核心词
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-26 21:43:31
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/examine/components/core.vue
-->
<template>
  <div class="negative-popup" @click="shows = false">
    <div class="negative" @click.stop>
      <div class="negative-text">输入核心词</div>
      <div class="negative-content">
        <div class="content-left">关键词：</div>
        <div class="content-data">
          <div class="content-input">
            <input
              type="text"
              v-model="content"
              placeholder="请输入核心词，以英文,分割关键词"
            />
          </div>
          <div class="content-tip">举例：云鼎汇富,短视频营销,AIGC</div>
        </div>
      </div>
      <div class="negative-btn">
        <div class="btn confirm" @click="handleConfirm">确认</div>
        <div class="btn" @click="shows = false">取消</div>
      </div>
      <div class="negative-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";
const URL = {
  editKeywords: "api/operation/material/editKeywords",
};

export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    this.content = this.data?.material_detail?.content;
    console.log(this.id);
    console.log(this.data.id);
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  methods: {
    async handleConfirm() {
      if (!this.content) {
        this.$message.error("请输入核心词");
        return;
      }
      await HTTP({
        url: URL.editKeywords,
        data: {
          auditId: +this.id,
          id: +this.data.id,
          content: this.content,
        },
      });
      this.$message.success("编辑成功");
      this.shows = false;
      this.$emit("update");
    },
  },
};
</script>

<style lang="scss" scoped>
.negative-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .negative {
    width: calc(516px + 162px);
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-content {
      display: flex;
      justify-content: center;
      margin-top: 45px;
      .content-left {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #353535;
        line-height: 40px;
      }
      .content-input {
        width: calc(91px + 207px + 91px);
        height: 40px;
        border: 1px solid #bec3cb;
        border-radius: 4px;
        background-color: #f6f9ff;
        input {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 4px;
          padding: 0 10px;
          background-color: #f6f9ff;
        }
      }
      .content-tip {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #7c7f97;
        margin-top: 25px;
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 45px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 16px;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
